import { useContext, useEffect, useState } from "react";
import { BsFillHeartPulseFill } from "react-icons/bs";
import { FaBed } from "react-icons/fa";
import { MdSignalWifiConnectedNoInternet2 } from "react-icons/md";
import { IoIosWifi } from "react-icons/io";
import "./style.css";
import { Link } from "react-router-dom";
import slugify from "react-slugify";
import { DasboardContext } from "../../context/dashboard-context";
import AlarmModal from "../AlarmModal/AlarmModal";
import LastFiveAlarm from "../LastFiveAlarm/LastFiveAlarm";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { FcInfo } from "react-icons/fc";

const SameSuitCard = ({ bed }) => {
  const [show, setShow] = useState(false);
  const [lastAlarmsShow, setLastAlarmsShow] = useState(false);
  const [minidata, setMinidata] = useState([]);
  const [suBed, setSuBed] = useState();
  const [alarms, setAlarms] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [alarm, setAlarm] = useState();

  const [outBedModalShow, setOutBedModalShow] = useState(true);

  const outBedClose = () => setOutBedModalShow(false);
  const outBedShow = () => setOutBedModalShow(true);

  const { states, events, topic, setCheckedItems } =
    useContext(DasboardContext);

  const [bedStatesMap, setBedStatesMap] = useState({});
  const [bedEventsMap, setBedEventsMap] = useState({});

  const [logs, setLogs] = useState({});

  const handleShow = () => setShow(true);
  const closeModal = () => setShow(false);
  const closeLastAlarmsModal = () => setLastAlarmsShow(false);

  const handleToggleChange = (deviceID, isChecked) => {
    if (isChecked) {
      setCheckedItems((prevItems) => [...prevItems, deviceID]);
    } else {
      setCheckedItems((prevItems) => prevItems.filter((id) => id !== deviceID));
    }
  };

  const symbols = {
    Mutfak: "countertops",
    Banyo: "bathtub",
    "Yatak Odası": "king_bed",
    "Yemek Odası": "table_restaurant",
    "Oturma Odası": "scene",
  };

  function extractIdFromUrl() {
    const parts = topic?.split("/");
    const idPart = parts?.find((part) => part.startsWith("id_"));
    return idPart || null;
  }

  async function getAlarmDetail() {
    try {
      const promises = bed.items.map(async (sbed) => {
        const response = await fetch(
          `https://app.vivasmartcare.com/api/alarm-logs/${sbed.deviceID}`
        );
        return response.json();
      });

      const alarmResponses = await Promise.all(promises);
      setAlarms((prevAlarms) => [...prevAlarms, ...alarmResponses]);
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  }

  async function getUserDetails() {
    try {
      const promises = bed?.items.map(async (sbed) => {
        const response = await fetch(
          `https://app.vivasmartcare.com/api/user/${sbed.person}`
        );
        return response.json();
      });
      const userDetails = await Promise.all(promises);
      setUserDetail((prevUser) => [...prevUser, ...userDetails]);
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  }

  function countAlarmsByDeviceId(alarms, deviceID) {
    let count = 0;

    alarms
      .filter((alarmArray) => alarmArray.length > 0)
      .forEach((alarmArray) => {
        const filteredAlarms = alarmArray.filter(
          (alarm) => alarm.deviceId === deviceID
        );
        count += filteredAlarms.length;
      });

    return count;
  }

  function dateFormat(myDate) {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZone: "Europe/Istanbul",
    };
    return new Date(myDate).toLocaleDateString("tr-TR", options);
  }

  useEffect(() => {
    setSuBed(bed);
    getAlarmDetail();
    getUserDetails();
  }, [bed]);

  useEffect(() => {
    const updateMaps = () => {
      const newBedStatesMap = { ...bedStatesMap };
      const newBedEventsMap = { ...bedEventsMap };

      bed.items.forEach((sbed) => {
        if (states.deviceId === sbed.deviceID) {
          newBedStatesMap[sbed.deviceID] = { ...states };
        }
        if (extractIdFromUrl(topic) === sbed.deviceID) {
          newBedEventsMap[sbed.deviceID] = { ...events };
        }
      });

      setBedStatesMap(newBedStatesMap);
      setBedEventsMap(newBedEventsMap);
    };

    updateMaps();

    // const fetchLogs = async () => {
    //   try {
    //     const response = await fetch(
    //       "https://app.vivasmartcare.com/api/vayyar-logs"
    //     );
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     const logs = await response.json();
    //     setLogs(logs);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    // fetchLogs().then(() => {
    //   if (logs && logs.length > 0) {
    //     const filteredLogs = logs.filter(
    //       (log) =>
    //         log.deviceId === "id_MEM6REM6N0U6RTU6NDg6NzA" &&
    //         log.dataType === "event"
    //     );

    //     const lastFilteredLog = filteredLogs[filteredLogs.length - 1];

    //     console.log(lastFilteredLog, filteredLogs);
    //   }
    // });
  }, [states, events]);

  return (
    <>
      <div className="bed-card">
        {bed?.items[0].organization && (
          <Link
            className="organisation-name"
            to={`/nursing-home-detail/${slugify(bed?.items[0].organization)}`}
          >
            {bed?.items[0].organization}
          </Link>
        )}

        {bed?.suiteName && bed?.suiteName !== "" && (
          <div className="room-name suite">{bed?.suiteName}</div>
        )}

        {bed?.items?.map((bedItem) => (
          <div key={bedItem?.deviceID} className="same-card">
            <div className="room-name">{bedItem?.roomName}</div>

            <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Bildirimler Kapalı"
                onChange={(e) =>
                  handleToggleChange(bedItem?.deviceID, e.target.checked)
                }
              />
            </Form.Group>

            {countAlarmsByDeviceId(alarms, bedItem.deviceID) > 3 ? (
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Alarm Sayısı{" "}
                    <span className="alarm-count">
                      {countAlarmsByDeviceId(alarms, bedItem.deviceID)}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {alarms
                      ?.filter((alarmArray) => alarmArray.length > 0)
                      .map((alarmArray, index) =>
                        alarmArray
                          .filter(
                            (alarm) => alarm.deviceId === bedItem.deviceID
                          )
                          .map((alarm, subIndex) => (
                            <div className="alarms">
                              <div
                                key={`${index}-${subIndex}`}
                                className={`alarm-type red`}
                                onClick={() => {
                                  handleShow();
                                  setAlarm(alarm);
                                }}
                              >
                                {alarm.eventType === "calling" &&
                                  "Düşme Alarmı"}
                              </div>
                            </div>
                          ))
                      )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : (
              alarms
                ?.filter((alarmArray) => alarmArray.length > 0)
                .map((alarmArray, index) =>
                  alarmArray
                    .filter((alarm) => alarm.deviceId === bedItem.deviceID)
                    .map((alarm, subIndex) => (
                      <div className="alarms">
                        <div
                          key={`${index}-${subIndex}`}
                          className={`alarm-type red`}
                          onClick={() => {
                            handleShow();
                            setAlarm(alarm);
                          }}
                        >
                          {alarm.eventType === "calling" && "Düşme Alarmı"}
                        </div>
                      </div>
                    ))
                )
            )}
            <div
              className="device-container"
              onClick={() => {
                setLastAlarmsShow(true);
              }}
            >
              <div className="device-wrapper">
                {/* {bedItem.brand === "Vayyar" ? (
                  bedItem.location === "Banyo" ? (
                    <FaToilet />
                  ) : bedItem.location === "Ev" ? (
                    <FaHome />
                  ) : bedItem.location === "Depo" ? (
                    <FaWarehouse />
                  ) : bedItem.location === "Bakım" ? (
                    <GrHostMaintenance />
                  ) : bedItem.location === "Kurum" ? (
                    <CgOrganisation />
                  ) : bedItem.location === "Demo" ? (
                    <FaCode />
                  ) : (
                    ""
                  )
                ) : (
                  <MdWatch />
                )} */}
                {/* Eski online offline kodu */}
                {/* {events &&
                  bedItem?.brand !== "Minifinder" &&
                  (bedEventsMap[bedItem?.deviceID]?.payload
                    ?.presenceDetected === true ? (
                    <span className="material-symbols-rounded in-bed">
                      {symbols[bedItem?.roomType]}
                    </span>
                  ) : (
                    <span className="material-symbols-rounded">
                      {symbols[bedItem?.roomType]}
                    </span>
                  ))} */}
                {logs &&
                  logs.length > 0 &&
                  (() => {
                    const filteredLogs = logs.filter(
                      (log) =>
                        log.deviceId === bedItem?.deviceID &&
                        log.dataType === "event"
                    );
                    const lastFilteredLog =
                      filteredLogs[filteredLogs.length - 1];

                    const isPresenceDetected =
                      lastFilteredLog &&
                      lastFilteredLog.vayyarData?.payload &&
                      lastFilteredLog.vayyarData.payload.presenceDetected ===
                        true;

                    return isPresenceDetected ? (
                      <>
                        <span
                          className={`material-symbols-rounded ${
                            isPresenceDetected && "in-bed"
                          }`}
                        >
                          {symbols[bedItem?.roomType]}
                        </span>
                      </>
                    ) : (
                      <span className="material-symbols-rounded">
                        {symbols[bedItem?.roomType]}
                      </span>
                    );
                  })()}

                {/* Vayyar Region Map for bedItem */}
                {logs &&
                  logs.length > 0 &&
                  (() => {
                    const filteredLogs = logs.filter(
                      (log) =>
                        log.deviceId === bedItem?.deviceID &&
                        log.dataType === "event"
                    );
                    const lastFilteredLog =
                      filteredLogs[filteredLogs.length - 1];

                    const isInBed =
                      lastFilteredLog &&
                      lastFilteredLog.vayyarData?.payload &&
                      lastFilteredLog.vayyarData?.payload.presenceRegionMap &&
                      lastFilteredLog.vayyarData?.payload
                        .presenceRegionMap[0] === 1;

                    return (
                      isInBed &&
                      bedItem?.brand !== "Minifinder" &&
                      bedItem?.roomType === "Yatak Odası" && (
                        <>
                          <FaBed fill="orange" />
                        </>
                      )
                    );
                  })()}

                {logs &&
                  logs.length > 0 &&
                  (() => {
                    const filteredLogs = logs.filter(
                      (log) =>
                        log.deviceId === bedItem?.deviceID &&
                        log.dataType === "event"
                    );

                    const lastFilteredLog =
                      filteredLogs[filteredLogs.length - 1];
                    const secondLastFilteredLog =
                      filteredLogs[filteredLogs.length - 2];

                    const isOutBed =
                      lastFilteredLog &&
                      secondLastFilteredLog &&
                      lastFilteredLog.vayyarData?.payload &&
                      lastFilteredLog.vayyarData?.payload.presenceRegionMap &&
                      secondLastFilteredLog.vayyarData?.payload &&
                      secondLastFilteredLog.vayyarData?.payload
                        .presenceRegionMap &&
                      lastFilteredLog.vayyarData?.payload
                        .presenceRegionMap[0] === 0 &&
                      secondLastFilteredLog.vayyarData?.payload
                        .presenceRegionMap[0] === 1;

                    return (
                      isOutBed &&
                      bedItem?.brand !== "Minifinder" &&
                      bedItem?.roomType === "Yatak Odası" && (
                        <>
                          <Modal show={outBedModalShow} onHide={outBedClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {bedItem.suiteName} adlı süit için yataktan
                                çıkma bildirimi
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body></Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={outBedClose}>
                                Kapat
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </>
                      )
                    );
                  })()}

                {/* Wifi Status */}
                {bedStatesMap[bedItem?.deviceID]?.wifiState.rssi <= -80 && (
                  <div className="rssi">!</div>
                )}

                {/* Vayyar Online Offline */}
                {bedStatesMap[bedItem?.deviceID] &&
                bedItem?.brand === "Vayyar" &&
                bedStatesMap[bedItem?.deviceID]?.status &&
                (bedStatesMap[bedItem?.deviceID]?.status === "test" ||
                  bedStatesMap[bedItem?.deviceID]?.status === "learning" ||
                  bedStatesMap[bedItem?.deviceID]?.status === "monitoring") ? (
                  <IoIosWifi fill="green" />
                ) : (
                  <MdSignalWifiConnectedNoInternet2 fill="red" />
                )}

                {/* Last Update */}
                {logs &&
                  logs.length > 0 &&
                  (() => {
                    const filteredLogs = logs.filter(
                      (log) =>
                        log.deviceId === bedItem?.deviceID &&
                        log.dataType === "event"
                    );
                    const lastFilteredLog =
                      filteredLogs[filteredLogs.length - 1];

                    const isPresenceDetected =
                      lastFilteredLog &&
                      lastFilteredLog.vayyarData?.payload &&
                      lastFilteredLog.vayyarData.payload.presenceDetected ===
                        true;

                    return (
                      isPresenceDetected && (
                        <OverlayTrigger
                          key={"top"}
                          placement={"top"}
                          overlay={
                            <Tooltip id={`tooltip-top`} className="last-update">
                              <span>Son Güncellenme Tarihi:</span>
                              <span>
                                {dateFormat(lastFilteredLog.createdAt)}
                              </span>
                            </Tooltip>
                          }
                        >
                          <Button style={{ all: "unset" }}>
                            <FcInfo />
                          </Button>
                        </OverlayTrigger>
                      )
                    );
                  })()}
              </div>
            </div>
          </div>
        ))}

        {bed.vital === true && (
          <div className="vital-container">
            <Link to="/">
              <BsFillHeartPulseFill color="#00A3E0" size={32} />
            </Link>
          </div>
        )}
      </div>

      {show === true && (
        <AlarmModal
          alarm={alarm}
          userDetail={userDetail}
          bed={bed}
          closeModal={closeModal}
        />
      )}

      {lastAlarmsShow === true && (
        <LastFiveAlarm
          id={bed.deviceID}
          closeLastAlarmsModal={closeLastAlarmsModal}
          lat={minidata?.lat || null}
          long={minidata?.long || null}
        />
      )}
    </>
  );
};

export default SameSuitCard;
